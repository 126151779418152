import { useState } from "react";
import { 
	Flex, 
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Text,
	useToast,
	Button,
	Input,
	Image,
	Select,
	Switch
} from "@chakra-ui/react";
import { isEmpty } from "../../utils";
import { useEditMusic } from "../../hooks";

export function CreatePushNotification({ onClose }) {
	const toast = useToast();
	const [loading, setLoading] = useState(false);

	const [title, setTitle] = useState("");
	const [body, setBody] = useState("");
	const [language, setLanguage] = useState('all')
	const [device, setDevice] = useState('all')

	async function handleSubmit(){
		setLoading(true);
		try {
			
			
			toast({
				status:"success",
				title:"Notifications sent!",
				position: 'top',
			})

			onClose();
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})
		}

		setLoading(false);
	}

	const languages = ["All","english", "arabic", "persian"]
	const deviceType = ["All", "ios", "android"]

	return (
		<Modal isOpen={true} onClose={onClose} size="lg" isCentered motionPreset='slideInBottom'>
			<ModalOverlay />
			<ModalContent borderRadius="15px">
				<ModalCloseButton mr="-5px" borderRadius="30px" color="lightText" />
				<Flex p="20px 15px 0" direction="column">
					<Text variant="title" fontSize="21px" mb="0px">Create Notification</Text>


					<Flex flex="1" mt="15px" direction="column">
						<Text variant="subtitle" mb="5px">Title</Text>
						<Input
							type="text"
							placeholder="Message Title"
							fontWeight="500"
							h="55px"
							p="10px"
							fontSize="15px"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							_focus={{
								borderColor:"primary",
								borderWidth:"2px"
							}}
						/>			
					</Flex>

					<Flex flex="1" mt="15px" direction="column">
						<Text variant="subtitle" mb="5px">Body</Text>
						<Input
							type="text"
							placeholder="Message Body"
							fontWeight="500"
							h="55px"
							p="10px"
							fontSize="15px"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							_focus={{
								borderColor:"primary",
								borderWidth:"2px"
							}}
						/>			
					</Flex>

					<Flex flex="1" mt="15px" direction="column">
						<Text variant="subtitle" mb="5px">Language</Text>
						<Flex mt="5px">
							{
								languages.map(lang => (
									<Flex onClick={() => setLanguage(lang.toLowerCase())} key={lang} p="5px 11px" bg={language == lang.toLowerCase() ? "dark" : "white"} borderRadius="30px" cursor="pointer">
										<Text color={language == lang.toLowerCase() ? "white" : "lightText"} textTransform="capitalize" fontSize="13px">{lang}</Text>
									</Flex>
								))
							}
						</Flex>
					</Flex>


					<Flex flex="1" mt="15px" direction="column">
						<Text variant="subtitle" mb="5px">Device Type</Text>
						<Flex mt="5px">
							{
								deviceType.map(dev => (
									<Flex onClick={() => setDevice(dev.toLowerCase())} key={dev} p="5px 11px" bg={device == dev.toLowerCase() ? "success" : "white"} borderRadius="30px" cursor="pointer">
										<Text color={device == dev.toLowerCase() ? "white" : "lightText"} fontSize="13px">{dev}</Text>
									</Flex>
								))
							}
						</Flex>
					</Flex>

					

					<Flex flex="1" w="100%" p="10px 0" borderTop="1px solid #f3f3f3" mt="20px">
						<Flex as={Button} variant="primary" isLoading={loading} onClick={handleSubmit} flex="2" h="50px" w="100%" p="14px 0" borderRadius="5px" align="center" justify="center" cursor="pointer">
							<Text variant="subtitle" color="white" fontSize="16px" fontWeight="600" opacity="1">Send notifications</Text>
						</Flex>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	)
}





















