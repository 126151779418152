import { useMutation, useQuery, useQueryClient } from 'react-query';
import Parse from "parse";


export const useNotifications = () => {
	return useQuery(['push-notifications'],
		async () => {
			const query = new Parse.Query("Notifications");
			query.limit(50);
			query.descending('createdAt');
			let data = await query.find();

			return data
		},
		{}
	)
}