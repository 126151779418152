import { useState } from "react";
import { Flex, Text, Button, SimpleGrid, Skeleton, Table, Thead, Tbody, Tr, Th, Td, Image } from '@chakra-ui/react';
import { format } from "date-fns";

import { Header, CreatePushNotification } from "../includes";
import { useNotifications } from "../hooks";

export default function PushNotifications() {
	const [onCreate, setOnCreate] = useState(false);
	const { data = [] } = useNotifications();

	return (
		<Flex direction="column">
			<Header />
			<Flex direction="column" p="30px 40px">
				<Flex align="center" justify="center" mb="10px">
					<Flex flex="1" direction="column">
						<Text mb="7px" variant="title">Push Notifications</Text>
					</Flex>
					<Button onClick={() => setOnCreate(true)} variant="primary" borderRadius="30px" fontSize="14px" p="12px 15px">Create notification</Button>
				</Flex>
				<Flex direction="column">
					<Flex w="100%" border="1px solid" mb="40px" maxH="950px" borderColor="light" borderRadius="10px" overflow="scroll">
						<Table variant='simple'>
							<Thead>
								<Tr>
									<Th w="150px"><Text variant="subtitle" textTransform="capitalize">Created At</Text></Th>
									<Th w="100px"><Text variant="subtitle" textTransform="capitalize">Token</Text></Th>
									<Th w="200px"><Text variant="subtitle" textTransform="capitalize">Local Profile ID</Text></Th>
									<Th isNumeric><Text variant="subtitle" textTransform="capitalize">Device</Text></Th>
									<Th isNumeric><Text variant="subtitle" textTransform="capitalize">Language</Text></Th>
								</Tr>
							</Thead>
							<Tbody>
								{
									data.map(row => (
										<NotificationRow key={row.id} data={row} />
									))
								}
							</Tbody>
						</Table>
					</Flex>
				</Flex>
			</Flex>
			{
				onCreate && <CreatePushNotification onClose={() => setOnCreate(false)} />
			}
		</Flex>
	)
}

function NotificationRow({ data }) {


	return (
		<Tr w="100%" bg="white">
			<Td pt="10px" pb="10px" fontSize="14px" fontWeight="500" textTransform="capitalize">{format(data.get('createdAt'), 'MM/dd/yyyy')}</Td>
			<Td pt="10px" pb="10px" fontSize="14px" fontWeight="500" textTransform="capitalize">{data.get('token')}</Td>
			
			<Td overflow="hidden" display="inline-block" whiteSpace="nowrap" w="200px" textOverflow="ellipsis" pt="10px" pb="10px" fontSize="14px" fontWeight="500" textTransform="capitalize">{data.get('localProfileId')}</Td>
			
			<Td isNumeric pt="10px" pb="10px" fontSize="14px" fontWeight="500">{data.get('deviceType')}</Td>
			<Td isNumeric pt="10px" pb="10px" fontSize="14px" fontWeight="500">{data.get('language')}</Td>
		</Tr>
	)
}