import { useMutation, useQuery, useQueryClient } from 'react-query';
import Parse from "parse";

export const useVideos = (id) => {
	return useQuery(['videos', id ? id : null],
		async () => {
			const query = new Parse.Query("Videos");
			if (id) {
				query.equalTo('album', {
					"__type":"Pointer",
					"objectId":id,
					"className":"VideoAlbums"
				});
			} else {
				query.equalTo('album', null);
				query.equalTo('MultiLanguageGroup', null);
			}
			let data = await query.find();
			let new_data = data;
			
			if (!id) {
				const query2 = new Parse.Query("MultiLanguageGroups");
				query2.equalTo('type', 'videos');
				let _data = await query2.find();

				_data = await Promise.all(_data.map(async group => {
					const videoQuery = new Parse.Query("Videos");
					videoQuery.equalTo('MultiLanguageGroup', group);
					videoQuery.ascending('order');
					group.videos = await videoQuery.find();

					if (group.videos.length > 0) {
						group.order = group.videos[0].get('order');
					}
					return group;
				}))
				new_data = data.concat(_data);
			}

			new_data.sort((a, b) => (a.order || a.get('order')) - (b.order || b.get('order')))

			return new_data;
		},
		{}
	)
}

export const useAddVideo = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {

		if (data.embeddedURL) {
			const query = new Parse.Query("Videos");
			query.equalTo('url', data.embeddedURL);
			let check_embedded_video  = await query.first();
			if (!check_embedded_video) {
				const Videos = Parse.Object.extend("Videos");
				const new_video = new Videos();

				if (data.category) {
					new_video.set("category", {
						"__type":"Pointer",
						"objectId":data.category,
						"className":"Categories"
					});
				}
				if (data.album) {
					new_video.set("album", {
						"__type":"Pointer",
						"objectId":data.album,
						"className":"VideoAlbums"
					});
				}
				new_video.set("title", data.title);
				new_video.set("description", data.description);

				if (data.embeddedImage && data.embeddedImage.base64) {
					let videoImageUrl = await Parse.Cloud.run('upload-image', {
						base64: data.embeddedImage.base64,
						type: data.embeddedImage.type,
						path: 'videos-thumbnails'
					});

					new_video.set("image", videoImageUrl);
				}
				if (data.embeddedURL) {
					new_video.set('url', data.embeddedURL)
				}

				new_video.set("author", "ShiaCircle");
				new_video.set("order", data.order);
				new_video.set('live', data.live || false);
				new_video.set('featured', data.featured || false);
				new_video.set('language', data.language || "");
				new_video.set('type', data.type);

				await new_video.save();
				queryClient.refetchQueries(['videos']);
				queryClient.refetchQueries(['video-albums']);

			} else {
				throw new Error('Embedded video has already been added!')
			}
		} else {
			const query = new Parse.Query("Videos");
			query.equalTo('videoID', data.videoID);
			let check_youtube_video  = await query.first();

			if (!check_youtube_video) {
				const Videos = Parse.Object.extend("Videos");
				const new_video = new Videos();

				new_video.set("category", {
					"__type":"Pointer",
					"objectId":data.category,
					"className":"Categories"
				});
				if (data.album) {
					new_video.set("album", {
						"__type":"Pointer",
						"objectId":data.album,
						"className":"VideoAlbums"
					});
				}

				new_video.set("title", data.title);
				new_video.set("description", data.description);
				new_video.set("image", data.image);
				new_video.set("author", data.author);
				new_video.set("videoID", data.videoID);
				new_video.set("youtubeCategoryID", data.youtubeCategoryID);
				new_video.set("order", data.order);
				new_video.set('live', data.live || false);
				new_video.set('featured', data.featured || false);
				new_video.set('language', data.language || "");
				new_video.set('type', data.type || "video");

				await new_video.save();
				queryClient.refetchQueries(['videos']);
				queryClient.refetchQueries(['video-albums']);

				return new_video;	
			} else {
				throw new Error('Youtube video has already been added!')
			}
		}

	}, {
		...options,
	})
}

export const useEditVideo = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {

		const query = new Parse.Query("Videos");
		let old_video  = await query.get(data.id)

		old_video.set("category", {
			"__type":"Pointer",
			"objectId":data.category,
			"className":"Categories"
		});
		old_video.set("title", data.title);
		old_video.set("author", data.author);
		old_video.set("description", data.description);
		old_video.set('live', data.live || false);
		old_video.set('featured', data.featured || false);
		old_video.set('language', data.language || "");
		
		if (data.album) {
			old_video.set("album", {
				"__type":"Pointer",
				"objectId":data.album,
				"className":"VideoAlbums"
			});
		} else {
			old_video.set('album', null)
		}

		await old_video.save();
		queryClient.refetchQueries(['videos']);
		queryClient.refetchQueries(['video-albums']);

		return old_video;	
	}, {
		...options,
	})
}

export const useDeleteVideo = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {

		const query = new Parse.Query("Videos");
		let old_video  = await query.get(data.id)

		await old_video.destroy();

		queryClient.refetchQueries(['videos']);
	}, {
		...options,
	})
}

export const useEditVideoOrder = (options = {}) => {
	const queryClient = useQueryClient();
	return useMutation(async (data) => {

		await Promise.all(data.map(async (video, index) => {
			video.set('order', index);
			await video.save();
		}));
		
		if (data.length > 0 && data[0].get('album')) {
			queryClient.refetchQueries(['videos', data[0].get('album').id]);
		} else {
			queryClient.refetchQueries(['videos']);
		}
	}, {
		...options,
	})
}




