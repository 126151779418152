import { useState, useEffect } from "react";
import { 
	Flex, 
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Text,
	useToast,
	Button,
	Input,
	Textarea,
	Image,
	Select,
	Switch
} from "@chakra-ui/react";
import { isEmpty, isURL, getYoutubeID } from "../../utils";
import { useCategories, useEditVideo, useDeleteVideo } from "../../hooks";

export function EditVideoModal({ video, onClose, albums }) {
	const toast = useToast();
	const [loading, setLoading] = useState(false);
	const [category, setCategory] = useState(video.get('category') ? video.get('category').id : null);
	const [title, setTitle] = useState(video.get('title'));
	const [album, setAlbum] = useState(video.get('album') ? video.get('album').id : null);
	const [author, setAuthor] = useState(video.get('author'));
	const [description, setDescription] = useState(video.get('description'));
	const [image, setImage] = useState(video.get('image'));
	const [live, setLive] = useState(video.get('live') || false);
	const [featured, setFeatured] = useState(video.get('featured') || false);
	const [language, setLanguage] = useState(video.get('language'))

	const [youtubeData, setYoutubeData] = useState();
	const { mutateAsync } = useEditVideo();
	const { mutateAsync: deleteVideo } = useDeleteVideo();

	const { data: categories } = useCategories('video');

	async function handleSubmit(){
		setLoading(true);

		try {
			let data = await mutateAsync({
				id: video.id,
				title,
				description,
				album,
				author,
				category,
				live,
				featured,
				language
			});
			onClose();
			toast({
				status:"success",
				title:"Video edited successfully!",
				position: 'top',
			})
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})
		}
		setLoading(false);
	}

	async function handleDelete(){
		setLoading(true);
		try {
			await deleteVideo({
				id: video.id
			})
			onClose();
			toast({
				status:"success",
				title:"Video deleted successfully!",
				position: 'top',
			})			
		}
		catch(error) {
			toast({
				status:"error",
				title:error.message,
				position: 'top',
			})
		}
		setLoading(false);
	}

	return (
		<Modal isOpen={true} onClose={onClose} size="lg" isCentered motionPreset='slideInBottom'>
			<ModalOverlay />
			<ModalContent borderRadius="15px">
				<ModalCloseButton mr="-5px" borderRadius="30px" color="lightText" />
				<Flex p="20px 15px 0" direction="column">
					<Text variant="title" fontSize="21px" mb="10px">Edit video details</Text>

					<Flex direction="column">
						<Flex direction="column" mb="20px">
							<Flex>
								<Text variant="subtitle">Image</Text>
								<Text ml="2px" color="error">*</Text>
							</Flex>
							<Flex cursor="pointer" direction="column" mt="5px" borderRadius="10px" h="150px" border="1px solid" borderColor="light" align="center" justify="center" overflow="hidden">
								{
									image ? 
									<Image src={image} w="100%" h="100%" objectFit="cover" objectPosition="center" />
									:
									null
								}
							</Flex>
						</Flex>

						<Flex w="100%" mb="20px">
							<Flex flex="1" direction="column" mr="10px">
								<Flex>
									<Text variant="subtitle">Title</Text>
									<Text ml="2px" color="error">*</Text>
								</Flex>
								<Input
									type="text"
									placeholder="Title"
									fontWeight="500"
									mt="5px"
									h="50px"
									p="10px"
									fontSize="14px"
									value={title}
									onChange={(e) => setTitle(e.target.value)}
									_focus={{
										borderColor:"primary",
										borderWidth:"2px"
									}}
								/>
							</Flex>
							<Flex flex="1" direction="column">
								<Flex>
									<Text variant="subtitle">Author</Text>
									<Text ml="2px" color="error">*</Text>
								</Flex>
								<Input
									type="text"
									placeholder="Author"
									fontWeight="500"
									mt="5px"
									h="50px"
									p="10px"
									fontSize="14px"
									value={author}
									onChange={(e) => setAuthor(e.target.value)}
									_focus={{
										borderColor:"primary",
										borderWidth:"2px"
									}}
								/>
							</Flex>
						</Flex>

						<Flex direction="column" mb="20px">
							<Flex>
								<Text variant="subtitle">Description</Text>
								<Text ml="2px" color="error">*</Text>
							</Flex>
							<Textarea
								type="text"
								placeholder="Set a compelling description"
								fontWeight="500"
								mt="5px"
								rows={4}
								resize="none"
								p="10px"
								fontSize="14px"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								_focus={{
									borderColor:"primary",
									borderWidth:"2px"
								}}
							/>
						</Flex>

						<Flex w="100%" mb="20px">
							<Flex flex="1" direction="column" mr="10px">
								<Flex>
									<Text variant="subtitle">Category</Text>
								</Flex>
								<Select value={category} onChange={(e) => setCategory(e.target.value)} mt="7px" w="100%" h="50px" variant='outline' fontWeight="500" fontSize="15px" placeholder='Select category'>
									{
										categories.map((category, index) => (
											<option key={index} value={category.id}>{category.title}</option>
										))
									}
								</Select>
							</Flex>
							<Flex flex="1" direction="column">
								<Flex>
									<Text variant="subtitle">Language</Text>
									<Text ml="2px" color="error">*</Text>
								</Flex>
								<Select value={language} onChange={(e) => setLanguage(e.target.value)} mt="5px" w="100%" h="50px" variant='outline' fontWeight="500" fontSize="15px" placeholder='Select language'>
									<option value="english">English</option>
									<option value="arabic">Arabic</option>
									<option value="persian">Persian</option>
									<option value="mixed">Mixed</option>
								</Select>						
							</Flex>
						</Flex>
						{
							!video.get('MultiLanguageGroup') && (
								<Flex direction="column">
									<Text variant="subtitle">Album</Text>
									<Select value={album} onChange={(e) => setAlbum(e.target.value)} w="100%" mt="5px" h="50px" variant='outline' fontWeight="500" fontSize="15px" placeholder='Select album'>
										{
											albums.map((album, index) => (
												<option key={index} value={album.id}>{album.title}</option>
											))
										}
									</Select>
								</Flex>
							)
						}
						<Flex mt="15px" align="center" justify="center">
							<Text flex="1" variant="subtitle">Live</Text>
							<Switch isChecked={live} onChange={(e) => setLive(e.target.checked)} colorScheme="green" size='md' />
						</Flex>
						<Flex mt="15px" align="center" justify="center">
							<Text flex="1" variant="subtitle">Featured</Text>
							<Switch isChecked={featured} onChange={(e) => setFeatured(e.target.checked)} colorScheme="green" size='md' />
						</Flex>
					</Flex>

					<Flex flex="1" w="100%" p="10px 0" borderTop="1px solid #f3f3f3" mt="20px">
						<Button onClick={handleDelete} variant="secondary" fontSize="15px" mr="10px" w="150px">Delete</Button>						

						<Flex as={Button} variant="primary" isDisabled={validate({ title, description, image, language })} isLoading={loading} onClick={handleSubmit} flex="2" h="50px" w="100%" p="14px 0" borderRadius="5px" align="center" justify="center" cursor="pointer">
							<Text variant="subtitle" color="white" fontSize="15px" fontWeight="600" opacity="1">Save video</Text>
						</Flex>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	)
}

function validate({ title, description, image, language }) {
	if (isEmpty(title)) {
		return true
	}
	if (isEmpty(description)) {
		return true
	}
	if (isEmpty(image)) {
		return true
	}
	if (isEmpty(language)) {
		return true
	}

	return false;
}







